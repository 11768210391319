.appt-book {
  #counters {
    @include breakpoint($landscape-down) {
      padding-top: 120px;
    }
  }
  .appt-book-page-header-content {
    @include breakpoint($landscape-down) {
      max-width: 60%;
    }
  }
  .appt-book-sections-container {
    #services {
      .book-now-bar {
        height: auto;
        .button {
          padding: 8px;
          height: auto;
        }
      }
      .service_tile-content {
        float: right;
        .selection-bar {
          .selection-time {
            width: 35%;
          }
          .selection-selector {
            width: 65%;
          }
        }
      }
    }
  }
  .appointment-select {
    .appointments-container {
      .book-appt-container {
        overflow: visible;
        .registration__email-list-text {
          font-family: "Neue Haas Unica Pro";
          font-size: 12px;
          font-variant: normal;
          font-weight: 400;
          letter-spacing: 0;
          line-height: 18px;
        }
        .registration__privacy-policy-list {
          float: left;
          margin: 30px 0;
        }
        .registration__email-list {
          height: 30px;
        }
      }
      .sign-in-container {
        overflow: visible;
      }
      .confirm-container {
        .appt-book-book-submit {
          width: 185px;
        }
      }
    }
  }
  .location-submit {
    width: 310px;
  }
}
