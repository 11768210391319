$lyl-screen-small: (width 1025px 1200px);
$lyl-screen-medium: (width 1201px 1445px);
$lyl-screen-large: (width 1446px 1700px);

.ff-results {
  &-buttons {
    .ff-button {
      @include swap_direction(padding, 3px);
      line-height: normal;
      height: 4em;
      @include breakpoint($medium-up) {
        @include swap_direction(padding, 0);
        line-height: 2.7em;
        height: 3em;
      }
      &--back {
        @include swap_direction(padding, 10px);
        @include breakpoint($medium-up) {
          @include swap_direction(padding, 0);
        }
      }
    }
  }
}

.pg_wrapper {
  .foundation_finder_results_wrapper {
    .ff-results {
      &-buttons {
        .ff-button {
          &--share-results {
            @include swap_direction(padding, 10px 0 0 0);
            @include breakpoint($medium-up) {
              @include swap_direction(padding, 0);
            }
          }
        }
      }
    }
  }
}

.site-header {
  &__menu {
    &-list {
      li.menu__item {
        @include breakpoint($medium-up) {
          @include swap_direction(margin, 0 12px);
        }
        .icon--search {
          @include breakpoint($medium-up) {
            @include swap_direction(padding, 0 8px 0 26px);
          }
        }
      }
    }
  }
  &.sticky {
    .site-header {
      .site-header__utility {
        &-item__link,
        .selectBox-label {
          @include breakpoint($lyl-screen-small) {
            font-size: 7px;
          }
          @include breakpoint($lyl-screen-medium) {
            font-size: 8px;
          }
        }
        &-item.site-header-utility-not-signed-in,
        &-item .selectBox.site-header__account-menu-select {
          @include breakpoint($lyl-screen-small) {
            width: 63px;
            min-width: 63px;
            padding-left: 0;
            font-size: 7px;
            .selectBox-arrow {
              right: 0;
              top: 0;
            }
          }
          @include breakpoint($lyl-screen-medium) {
            width: 75px;
            min-width: 75px;
            padding-left: 0;
            font-size: 8px;
            .selectBox-arrow {
              right: 2px;
              top: 0;
            }
          }
          @include breakpoint($lyl-screen-large) {
            width: 85px;
            min-width: 85px;
            font-size: 9px;
            .selectBox-arrow {
              right: 2px;
              top: 0;
            }
          }
        }
      }
      .menu__item--category-wrapper {
        @include breakpoint($lyl-screen-small) {
          font-size: 7px;
        }
        @include breakpoint($lyl-screen-medium) {
          font-size: 9px;
        }
        @include breakpoint($lyl-screen-large) {
          font-size: 11px;
        }
      }
      &__menu-wrapper {
        margin-right: 8em;
      }
    }
  }
}

.tooltip {
  span {
    &:not([class]) {
      width: 17px;
      height: 17px;
      display: inline-block;
      border-radius: 50%;
      background: $color-black;
      color: $color-white;
      font-size: 11px;
      text-transform: lowercase;
      text-align: center;
      margin-#{$rdirection}: 5px;
    }
  }
  .tooltiptext-over {
    .waitlist-legal-copy {
      line-height: 13px;
      font-size: 12px;
      text-align: $ldirection;
    }
  }
}

#pr-write {
  .p-w-r {
    .pr-header-required {
      display: block;
    }
  }
}

.spp_reviews {
  .pr {
    &-snippet {
      &-rating-decimal {
        &:after {
          content: ' sur 5' !important;
        }
      }
    }
    &-review-display {
      .pr-rd {
        &-review-header-contents {
          &:before {
            content: ' FILTRER PAR ' !important;
          }
        }
        &-sort-group {
          &:before {
            content: ' RANGER PAR ' !important;
          }
        }
      }
    }
    &-helpful-yes {
      .pr-helpful-count {
        &:before {
          content: ' Oui ' !important;
        }
      }
    }
    &-helpful-no {
      .pr-helpful-count {
        &:before {
          content: ' Non ' !important;
        }
      }
    }
  }
}

.gnav-util {
  .tooltiptext-over {
    overflow-y: scroll;
    height: 150px;
  }
}

.waitlist-form {
  &__email {
    width: 240px;
  }
  input[type='submit'] {
    &.waitlist-form {
      &__submit {
        margin-top: 15px;
        @include breakpoint($medium-up) {
          margin-top: 0;
        }
      }
    }
  }
}

#bt_settings {
  .bt-content {
    .slide-toggle {
      background: transparent url('/media/images/cookies/m_all-off_fr.png');
      @include breakpoint($medium-up) {
        background: transparent url('/media/images/cookies/all-off_fr.png') 100% 100% no-repeat;
      }
    }
    .mask {
      .slide-toggle {
        background-image: url('/media/images/cookies/m_all-on_fr.png');
        @include breakpoint($medium-up) {
          background: transparent url('/media/images/cookies/all-on_fr.png') 100% 100% no-repeat;
        }
      }
    }
  }
}

.section-chatprivacypolicy {
  .site-header,
  .footer {
    display: none;
  }
  .site-content {
    margin-top: 0;
    padding-top: 0;
    &__offer-banner {
      display: none;
    }
  }
}

.gnav-util {
  &__content {
    .change-password {
      .password-field {
        &__rules {
          font-size: 11px;
        }
      }
    }
  }
}

.regimen-module {
  .regimen-category {
    padding: 0 10px;
  }
}

// Foundation VTO Rollout
.enable-vto-foundation {
  .product-foundation-vto {
    &__overlay-button {
      width: 60%;
    }
    &__container .shade-list-vto {
      .swatches--single.vto-filter .vto-filter-text {
        margin-top: 5px;
        white-space: normal;
      }
      .selected.vto-filter .vto-filter-text {
        margin-top: 2px;
      }
    }
  }
  #onetrust-consent-sdk,
  .LPMcontainer {
    display: none !important;
  }
}
.product-full {
  &__offer {
    &__url {
      flex-wrap: wrap;
    }
  }
  &.product-full-v1 {
    .product-full__sku-selector-container {
      width: 104%;
    }
  }
}

.checkout {
  .ship-method-group {
    border-bottom: 1px solid;
  }
  .delivery-options {
    padding: 5px 30px 9px;
    clear: both;
    border-top: 1px solid;
    display: block;
  }
  .btn-disable {
    pointer-events: none;
  }
}

.multi-carrier-enabled {
  &.active-panel-shipping {
    .checkout {
      &__sidebar {
        .checkout-panel {
          &--shipmethod {
            &.display {
              display: block;
            }
          }
        }
      }
    }
  }
  .checkout {
    &__sidebar {
      .checkout-panel {
        &--shipmethod {
          &.display {
            display: none;
          }
        }
      }
    }
  }
}

@if not $cr22 {
  .sticky-add-to-bag {
    &:not(.sticky-add-to-bag-v2) {
      .sticky-add-to-bag__section.multi-size {
        @include breakpoint($extra-extra-large-up) {
          max-width: 45%;
        }
      }
    }
  }
}
