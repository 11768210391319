$x-small-down: max-width 280px;

.welcome15-overlay {
  #cboxLoadedContent {
    @include swap_direction(padding, 0);
    background-color: $color-dark-blue;
    .welcome-offer-popup-wrapper {
      text-align: center;
      background-image: url(/media/export/cms/loyalty/welcome_popup_banner_mobile.png);
      background-size: 100% 100%;
      @include breakpoint($landscape-up) {
        background-image: url(/media/export/cms/loyalty/welcome_popup_banner_pc.png);
        background-repeat: no-repeat;
      }
      &__header {
        visibility: hidden;
        height: 300px;
        @include breakpoint($landscape-up) {
          height: 240px;
        }
      }
      &__subheader {
        @include swap_direction(padding, 0 20px);
        font-size: 24px;
        color: $color-white;
        font-family: $font--subheading;
        font-style: italic;
        line-height: 1.25;
        @include breakpoint($landscape-up) {
          @include swap_direction(padding, 0 10%);
        }
      }
      &__button {
        @include swap_direction(margin, 20px 0 10px 0);
        padding-#{$ldirection}: 50px;
        padding-#{$rdirection}: 50px;
        a {
          text-decoration: none;
          font-size: 11px;
        }
        &:hover {
          color: $color-white;
          a {
            color: $color-white;
          }
        }
      }
      &__footer {
        color: $color-white;
        padding-bottom: 0;
        margin-bottom: 10px;
        font-family: $font--text;
        font-size: 13px;
        a {
          color: $color-white;
          font-size: 13px;
          font-family: $font--text;
          text-transform: uppercase;
          @include breakpoint($landscape-up) {
            text-transform: initial;
          }
        }
      }
    }
  }
}

@media (min-width: $medium-up) {
  .site-footer {
    .site-footer-pc__column {
      .site-footer__country-chooser-wrapper {
        margin-top: 10px;
        position: unset;
      }
    }
  }
}

.selectBox-dropdown-menu {
  width: 170px !important;
}

@include breakpoint($medium-portrait-only) {
  .loyalty__market__status__header__tier- {
    &1,
    &2,
    &3 {
      .loyalty__market__status__header__title {
        font-size: 18px;
      }
    }
  }
  .loyalty__market__status__content .loyalty__market__status__section {
    padding: 5px;
  }
  section.checkout-panel--shipping fieldset {
    .form-item {
      width: 100%;
    }
  }
  .checkout.index {
    .column.left {
      section.checkout-panel--review {
        #checkout_payment {
          .card-exp {
            float: none;
          }
        }
      }
    }
  }
}

.loyalty__panel__offers {
  &__list-container {
    .loyalty__panel__offers__offer-data-container {
      min-height: 180px;
      .loyalty__panel__offers__promo_desc {
        min-height: 88px;
      }
    }
  }
  &__sku__error {
    text-align: center;
    color: $color-error;
    font-family: $font--text;
  }
}

.checkout {
  &-panel--donations {
    display: none;
  }
}

.checkout-panel__content__learn-more {
  text-align: center;
}

// ipad fixes:
.loyalty__panel__offers__sku__product-image-container {
  img {
    @media only screen and (min-width: 768px) and (max-width: 1024px) {
      height: inherit;
    }
  }
}

#loyalty__panel__offers__sku {
  .loyalty__panel__subtitle.mobile-hidden {
    @media only screen and (min-width: 768px) and (max-width: 1024px) {
      display: block;
    }
  }
  .loyalty__panel__offers__sku__list-container {
    .slides li {
      @media only screen and (min-width: 768px) and (max-width: 1024px) {
        width: 32%;
        text-align: center;
        display: inline-block;
        margin: 0 33% 20px;
      }
    }
  }
}

.loyalty__panel__offers__sku__cta__container .loyalty__panel__product__button:focus,
.loyalty__panel__offers__button-container .button--disabled:focus {
  outline: 0 !important;
}

#non__loyalty__panel {
  @include breakpoint($medium-up) {
    text-align: center;
  }
}

.new-account {
  .new-account__container {
    .new-account__item.checkbox__content,
    .new-account__item.registration-notice {
      text-align: left;
    }
    .new-account__item__communication_consent {
      text-align: left;
      margin-bottom: 10px;
    }
  }
}

.non__loyalty__panel {
  p {
    text-align: left;
  }
  .new-account__item_phone1 {
    text-align: left;
    margin: 10px 20px;
  }
  .non__loyalty__panel__join-now {
    margin-bottom: 10px;
    .local-grid__row.main {
      width: 80%;
      margin: 0 auto;
      .local-grid__col-20 {
        width: 20%;
      }
      .local-grid__col-80 {
        width: 78%;
        margin-left: 2%;
      }
      .local-grid__col-40 {
        width: 40%;
      }
      .local-grid__col {
        float: left;
        .selectbox {
          width: 90%;
        }
        #form--loyalty_join--field--POSTAL_CODE {
          width: 100%;
        }
      }
    }
  }
}

// Marketing Page
.loyalty__market__status__header {
  @include breakpoint($medium-up) {
    display: flex;
    justify-content: space-evenly;
  }
}

.loyalty__market__status__content__field {
  @include breakpoint($medium-up) {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding-bottom: 0.5em;
    padding-top: 0.5em;
  }
}

@include breakpoint($medium-up) {
  .tier-1 .loyalty__market__status__content {
    color: $color-marine-text;
    font-size: 0.9em;
    &__empty {
      background: $color-marine;
    }
  }
  .tier-2 .loyalty__market__status__content {
    color: $color-aquamarine-text;
    font-size: 0.9em;
    &__empty {
      background: $color-aquamarine;
    }
  }
  .tier-3 .loyalty__market__status__content {
    color: $color-ultramarine;
    font-size: 0.9em;
    &__empty {
      background: $color-ultramarine;
    }
  }
}

// Max width: 361px
@include breakpoint($small-med-only) {
  // GNAV bottom menu
  .site-header__menu__mobile-util-links.pc-hidden {
    font-size: 0.8em;
  }
  // Landing Page
  .loyalty__market__header__text {
    font-size: 0.9em;
  }
  .loyalty__market__status__header.accordion.js-accordion {
    padding: 1em 1em;
  }
  .loyalty__market__status__header {
    &__title {
      font-size: 0.9em !important;
      letter-spacing: -0.1em;
    }
    &__points {
      font-size: 0.8em !important;
    }
  }
}

// Max width: 767px
@include breakpoint($small-only) {
  // Landing Page
  .loyalty__market__header__text {
    bottom: 0px;
  }
}

// Galaxy Fold
@include breakpoint($x-small-down) {
  .site-header__menu__mobile-util-links.pc-hidden {
    font-size: 0.7em;
  }
  .loyalty__market__status__header {
    &__title {
      padding-top: 0.6em !important;
      font-size: 0.9em !important;
      letter-spacing: -0.1em;
    }
    &__logo {
      width: 33px !important;
      height: 33px !important;
    }
    &__points {
      font-size: 0.7em !important;
      padding-top: 0.7em !important;
    }
  }
}

// Account & Registration
.new-account__section {
  .demographic-form {
    display: flex;
    flex-direction: column;
  }
}

.demographic-container {
  @include breakpoint($medium-up) {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding: 2em;
  }
}

#form--registration_short--field--POSTAL_CODE,
#form--registration_short--field--BIRTH_MONTH,
#form--registration_short--field--BIRTH_DAY {
  height: 47px;
  border-radius: 4px;
  width: 100%;
  font-family: 'Neue Haas Unica Pro';
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}

.slick-slide.slick-current.slick-active {
  outline: 0 !important;
}

#form--errors {
  color: $color-error;
}

.loyalty__panel__offers__sku__success,
.loyalty__panel__offers__brand__success {
  text-align: center;
  font-size: 14px;
  color: $color--success;
}

.non__loyalty__panel__join-now {
  text-align: left;
}

// Loyalty offes carousel
.loyalty__panel__offers__offer-wrapper {
  height: 650px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 30px;
}

//Loyalty Cancellation popup
@include breakpoint($medium-down) {
  .loyalty-cancel-overlay#colorbox {
    #cboxClose {
      top: 9px;
      right: 15px;
    }
    #cboxLoadedContent {
      .loyalty--popover {
        &__header {
          padding-top: 8px;
          padding-left: 4px;
        }
        &__sub--header,
        &__form,
        &__contact--us {
          max-width: 98%;
          margin-left: 5px;
        }
      }
    }
  }
}

// Order History
.orders-list__item {
  span {
    width: 60%;
    font-weight: 100;
    font-size: smaller;
  }
}

section {
  .checkout__content {
    .checkout-panel--viewcart {
      .cart-items__item {
        @include breakpoint($medium-up) {
          border-top: 1px solid $color-lightest-grey;
        }
      }
    }
  }
}
