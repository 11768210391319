.favorites__item {
  .product-info {
    &__text {
      a {
        &:last-child {
          display: block;
        }
      }
      .button {
        max-width: 170px;
        @include breakpoint($medium-up) {
          max-width: 140px;
        }
      }
    }
  }
}

span {
  &.js-order-history-accordion {
    &.icon {
      &.icon--plus {
        width: 100%;
      }
    }
  }
}

.change-password {
  .profile__change-password-overlay {
    .profile__reset-password {
      .profile-info {
        &__header {
          font-size: 16px;
        }
      }
    }
  }
}
