section {
  .checkout-panel {
    &--shipping {
      &-edit-address {
        .address-form {
          &__city-state-zip {
            .postal-code {
              width: 100%;
              @include breakpoint($medium-up) {
                width: 50%;
                padding-#{$rdirection}: 20px;
              }
            }
            .city {
              @include breakpoint($medium-up) {
                width: 50%;
                padding-#{$rdirection}: 0;
              }
            }
          }
        }
        .form-item {
          &.address4 {
            display: none;
          }
        }
      }
    }
  }
}

.address-book-page {
  &__overlay {
    .address-form {
      .form-field.postalcode_state-field {
        width: 48%;
        padding-#{$rdirection}: 8px;
        float: $ldirection;
        input[type='text'] {
          width: 100%;
        }
      }
    }
  }
}

.active-panel-review {
  section {
    .checkout-panel {
      .payment-type-field {
        h3 {
          display: none;
          @include breakpoint($medium-up) {
            display: block;
          }
          &.checkout {
            &__subtitle {
              &__creditcard {
                @include breakpoint($medium-up) {
                  display: none;
                }
              }
            }
          }
        }
        .payment-type {
          &.direct-debit {
            display: none;
          }
        }
      }
    }
  }
}

.active-panel-shipping {
  h4 {
    &.checkout {
      &__panel-subtitle {
        display: none;
      }
    }
  }
}
